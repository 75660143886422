import httpClient from './httpClient';

const END_POINT = '/clients';

const getAllClients = () => httpClient.get(END_POINT);

const getClient = (id) => httpClient.get(END_POINT + '/' + id);

const getProjects = (id) => httpClient.get(END_POINT + '/' + id + '/projects');

const createClient = (client) => httpClient.post(END_POINT, { client });

const updateClient = (id, client) => httpClient.put(END_POINT + '/' + id, { client } );

const deleteClient = (id) => httpClient.delete(END_POINT + '/' + id);

const getInvoices = (id) => httpClient.get(END_POINT + '/' + id + '/invoices');

const synchronizeClients = () => httpClient.post(END_POINT + '/synchronize');
export {
    getAllClients,
    getClient,
    getProjects,
    createClient,
    updateClient,
    deleteClient,
    getInvoices,
    synchronizeClients
}

